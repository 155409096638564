import React from 'react';
import { Link } from 'gatsby'

import styles from './products.module.scss';

import Layout from '../../components/layout';
import CallToAction from '../../components/cta';
import WayWeWork from '../../components/www';

import { tablet, diploma, hat, presentation } from '../../images/icons';
import { teaching_team } from '../../images/team';


export default () => (
  <Layout>
    <div className={styles.productPage}>
      <div className={styles.topSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>SSAT Test Prep</div>
          <div className={styles.description}>With weekly classes that provide regular practice, intensive programs that deliver quick gains, and private tutoring that targets individual needs, Blueprint has the right SSAT program for you.</div>
        </div>
      </div>
      <div className={styles.iconSection}>
        <div className={styles.card}>
          <img src={tablet} />
          <div className={styles.meta}>A Culture of Achievement</div>
          {/*<div className={styles.description}>Every student that joins our SAT program receives a customized plan for their preparation that is based on data. Using this cutting-edge analytical approach, we can learn the precise concepts each student struggles with on the exam. This allows us to provide customized and dynamic guidance to each student about how they can better improve upon their weaknesses.</div>*/}
          <div className={styles.description}>We believe every student can become good at test-taking. Our SSAT classes immerse students in a culture that emphasizes not only the mastery of academic content but also a mindset of focus, determination, and growth. Students are trained in memorization, concentration, verbal reasoning, mathematical thinking, and essay writing.</div>
        </div>
        <div className={styles.card}>
          <img src={diploma} />
          <div className={styles.meta}>Improve by 20 Percentile Points</div>
          {/*<div className={styles.description}>Students who enroll in at least 60 of hours of our SAT preparation program can expect to gain up to 200 points on their score.  This improvement is based on our curriculum, which emphasizes familiarity with the exam; just like any sport, mastering the SAT is all about PRACTICE--so in each of our lesson formats, we administer regular mock exams and tailored review sessions.</div>*/}
          <div className={styles.description}>Students who enroll in a weekly SSAT program and one intensive program can expect to gain up 20 percentile points or more. Our curriculum includes the memorization of 1,500 key vocabulary words, critical reading strategies, analogical reasoning, and math practice.</div>
        </div>
        <div className={styles.card}>
          <img src={presentation} />
          <div className={styles.meta}>Dedicated & Experienced Instructors</div>
          {/*<div className={styles.description}>In addition to degrees from world class universities, all our instructors scored in the 99th percentile on both the SAT & ACT and are subject-matter experts in the specific subjects they each. Most importantly, they are teachers at heart and are heavily invested in the improvement of each individual student. Learn more about our amazing team here.</div>*/}
          <div className={styles.description}>Every instructor on our team has many years worth of experience coaching students to achieve their best on the SSAT. They have worked with students preparing for every level of the test—Elementary, Middle and Upper—and tailor their teaching to each student. <Link to='/team/main'>Our team of boarding school counsellors</Link> is also available to help students place their SSAT journey within the context of their larger goals.</div>
        </div>
      </div>
      <WayWeWork />
      <CallToAction text='Interested in signing up for this course?' cta='Register Today'/>
      <div className={styles.formatSection}>
        <div className={styles.header}>Lesson Formats</div>
        <div className={styles.description}>We offer all of our classes in a variety of formats. Please get in touch with us to discuss which options best suit your schedule.</div>
          <div className={styles.row}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>Intensive Program</div>
                <div className={styles.meta}>1-3 Weeks</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Offered during all school breaks</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Vocabulary memorization system</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>•  2 to 3 mock exams per program</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Tailored subject instruction</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>Weekly Classes</div>
                <div className={styles.meta}>3-4 Months</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Weekends & weekdays</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Analytics-based exam feedback</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>2 to 3 mock exams per term</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Tailored subject instruction</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>1-on-1 Courses</div>
                <div className={styles.meta}>Year round</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Flexible scheduling</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Individualized curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>On demand mock exams</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Analytics-based instruction</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)